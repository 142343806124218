@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.btn {
		@apply px-6 py-3 uppercase block w-fit whitespace-nowrap cursor-pointer transition-colors;
	}

	.btn--primary {
		@apply bg-cano-red text-white font-medium hover:bg-cano-red-600;
	}
}

@layer base {
	h1,h2,h3,h4,h5,h6 {
		@apply font-display font-medium;
	}

	.swiper-pagination-bullet {
		width: 24px !important; 
	}
}

#frontpage-products-purpose .swiper-pagination-bullet {
	width: 12px;
	height: 12px;
}

#frontpage-products-purpose .swiper-pagination-bullet-active {
	background: #E51822;
}

#frontpage-products-purpose__pagination {
	display: flex;
	align-items: center;
	justify-content: center;
} 